import { Stack, Button, Link } from '@material-ui/core'
import { useState, useEffect, useRef, useMemo } from 'react'
import MenuPopover from 'src/components/MenuPopover'
import { useSampleMetadata } from 'src/api/vcf'
import DashboardIcon from '@mui/icons-material/Dashboard'

const AnalysisPopover = ({ fileId, sampleName }) => {
  const sampleMetadata = useSampleMetadata({ fileId: fileId, sampleName })
  const anchorRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const tables = useMemo(() => sampleMetadata.query.data?.tables ?? [], [sampleMetadata.query.data])

  const handleOpenMenu = () => {
    setOpenMenu(true)
  }

  const handleCloseMenu = () => {
    setOpenMenu(false)
  }

  useEffect(() => {}, [fileId])

  return (
    <>
      <Button variant="contained" onClick={handleOpenMenu} ref={anchorRef} sx={{ bgcolor: '#b04d33' }}>
        <Stack direction="row" spacing={1}>
          <DashboardIcon></DashboardIcon>
          {/* <Typography>Go to Analysis</Typography> */}
        </Stack>
      </Button>
      <MenuPopover
        open={openMenu}
        onClose={handleCloseMenu}
        anchorEl={anchorRef.current}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {tables.map((item, index) => (
          <Link href={`/libra/variants/${fileId}/${sampleName}/${item.id}`} underline="none">
            <Button variant="outlined" size="small" sx={{ margin: 1, color: '#b04d33', borderColor: '#b04d33' }}>
              {item.title}
            </Button>
          </Link>
        ))}
      </MenuPopover>
    </>
  )
}

export default AnalysisPopover
