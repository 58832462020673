import CONSEQUENCE_TO_IMPACT_MAP from './consequenceImpact.json'
import HPO_RAW_DATA from './hpo.json'
import GENE_CHOICES from './genes.json'
import IMPACT_CHOICES from './impactChoices.json'
import DISEASE_CHOICES from './diseases.json'
import CLIN_VAR_SEVERITY from './clinVar.json'
import HPO_DETAILED from './hpoDetailed.json'
import { PATH_DASHBOARD } from '../routes/paths'
import { ArrowBack, TableView, Dashboard } from '@material-ui/icons'

export const IMPACT_CHOICES_MAP = {
  LOW: Object.fromEntries(IMPACT_CHOICES.LOW.map((c) => [c.value, c])),
  MODERATE: Object.fromEntries(IMPACT_CHOICES.MODERATE.map((c) => [c.value, c])),
  HIGH: Object.fromEntries(IMPACT_CHOICES.HIGH.map((c) => [c.value, c])),
  MODIFIER: Object.fromEntries(IMPACT_CHOICES.MODIFIER.map((c) => [c.value, c])),
}

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const HPO_OPTIONS = HPO_RAW_DATA.map(([id, name]) => ({
  value: id,
  label: `${name} (${id})`,
}))

export const FILTER_OPTIONS = ['Low Coverage', 'ACMG Incidentals']

export const GENE_PANEL_DEFAULTS = [
  { value: 'Epilepsy Panel - Epigenetiks', label: 'Epilepsy Panel - Epigenetiks' },
  { value: 'Autoimmune Panel - Epigenetiks', label: 'Autoimmune Panel - Epigenetiks' },
  { value: 'Cardio Panel - Epigenetiks', label: 'Cardio Panel - Epigenetiks' },
  { value: 'Food Allergy Panel - Epigenetiks', label: 'Food Allergy Panel - Epigenetiks' },
]

export const HPO_MAP = Object.fromEntries(HPO_OPTIONS.map((option) => [option.value, option]))
export const HPO_EXTENDED_OPTIONS = HPO_RAW_DATA.map(([id, name]) => ({
  value: `${name} (${id})`,
  label: `${name} (${id})`,
  id,
}))

export const HPO_DETAILED_DATA = HPO_DETAILED

export { IMPACT_CHOICES, CONSEQUENCE_TO_IMPACT_MAP, CLIN_VAR_SEVERITY, GENE_CHOICES, DISEASE_CHOICES }

export const GENES_TAB_HEIGHT = 600

export * from './pvs1'
export * from './acmg'

export const SEVERITY_COLOR_ORDER = ['default', 'success', 'warning', 'error']

export const DASHBOARD_CONFIG = [
  {
    title: 'Files',
    path: PATH_DASHBOARD.general.files,
    icon: <ArrowBack />,
  },
]

export const NAVBAR_CONFIG = [
  // { We don't need this anymore
  //   title: 'Files',
  //   path: PATH_DASHBOARD.general.files,
  //   icon: <ArrowBack />,
  // },
  {
    title: 'Variant Dashboard',
    path: PATH_DASHBOARD.general.variantDashboard,
    icon: <Dashboard />,
  },
]

export const VAR_DETAILS_NAVBAR_CONFIG = [
  {
    title: 'Files',
    path: PATH_DASHBOARD.general.files,
    icon: <ArrowBack />,
  },
  {
    title: 'Variant Dashboard',
    path: PATH_DASHBOARD.general.variantDashboard,
    icon: <Dashboard />,
  },
  {
    title: 'Variants',
    path: PATH_DASHBOARD.general.variants,
    icon: <TableView />,
  },
]

export const COLOR_ENCODE_DESCRIPTIONS = {
  OMIM: 'Variant is present in OMIM, have a "pathogenic" possibility in ClinVar and have allele frequencies ≤ 0.01 in gnomAD, ExAC and 1kg (Genotype)',
  SEVERE: '"stop_gained" and "stop_lost" variant',
  FRAMESHIFT: 'Frameshift variant',
  SPLICE: 'Splice donor and splice acceptor variant',
  MISSENSE: 'Missense variant',
}

export const LOW_COVERAGE_GENES = [
  'NDUFA2',
  'PGAP3',
  'WAS',
  'SLC2A10',
  'COL2A1',
  'CRLF1',
  'PCSK9',
  'COL5A1',
  'ERCC6',
  'RB1',
  'MAPT',
  'UNC13D',
  'NDE1',
  'SLC25A22',
  'ACADSB',
  'MT-ND5',
  'CLPP',
  'SLC9A6',
  'FLNA',
  'TBCD',
  'RYR1',
  'FBN1',
  'ALDH7A1',
  'MMADHC',
  'POMT2',
  'SYNGAP1',
  'NSD1',
  'ATXN10',
  'FTL',
  'CARD14',
  'GCK',
  'PAFAH1B1',
  'PHF8',
  'PDHA1',
  'FMR1',
  'ENG',
  'OPA1',
  'IDS',
  'RYR2',
  'AGL',
  'KRIT1',
  'TRNT1',
  'POLR3B',
  'TTC7A',
  'CSTB',
  'FBN2',
  'RAPSN',
  'CLCN4',
  'ROGDI',
  'ALG6',
  'UBE3A',
  'BTK',
  'PIGN',
  'SLC37A4',
  'CDKL5',
  'TTN',
  'LAMP2',
  'CRH',
  'DSP',
  'CSPP1',
  'STT3B',
  'NLRP12',
  'STXBP1',
  'GRIN2A',
  'NANS',
  'MT-ND1',
  'PPT1',
  'MYO6',
  'TAF1',
  'MKS1',
  'DEPDC5',
  'GNAO1',
  'MT-ATP8',
  'PEX7',
  'POLG',
  'MLYCD',
  'D2HGDH',
  'SMARCA4',
  'CEP290',
  'TRPM1',
  'TPP1',
  'CYBA',
  'JAG1',
  'OCLN',
  'MBTPS2',
  'MT-CYB',
  'LDLR',
  'GLA',
  'DES',
  'WDR45',
  'SLC16A1',
  'DMD',
  'ITPR1',
  'ATP13A2',
  'SLC2A1',
  'COG7',
  'NF1',
  'ETHE1',
  'LMNA',
  'SCN1A',
  'CP',
  'CDKN2A',
  'KCNQ1',
  'MYLK',
  'L1CAM',
  'TPK1',
  'OTC',
  'ATP6AP2',
  'MT-CO3',
  'KDM5C',
  'BSCL2',
  'NPC1',
  'ITPA',
  'TGFB3',
  'SSR4',
  'EHMT1',
  'PRKAR1A',
  'TRAPPC11',
  'CLN6',
  'GBE1',
  'SLC1A4',
  'SDHA',
  'PNKP',
  'DPAGT1',
  'KCNJ11',
  'SCARB2',
  'GALC',
  'ATP7A',
  'TSC2',
  'NDUFV1',
  'MT-ND4',
  'FGFR2',
  'COL3A1',
  'UQCC2',
  'F2',
  'PTCH1',
  'PNP',
  'HEXA',
  'ABCC8',
  'PDHX',
  'ZAP70',
  'CYBB',
  'GPX4',
  'PARN',
  'SH2D1A',
  'EMX2',
  'STK11',
  'SMARCB1',
  'WHSC1',
  'FKRP',
  'EFTUD2',
  'GRIN2B',
  'VPS53',
  'ADA',
  'PGK1',
  'ASPM',
  'AP1S2',
  'ANOS1',
  'MFSD8',
  'TBC1D24',
  'PLA2G6',
  'GFAP',
  'STAT2',
  'NGLY1',
  'SHANK3',
  'COL5A2',
  'SLC7A7',
  'KCNQ2',
  'DARS2',
  'OFD1',
  'FBXL4',
  'INS',
  'HADHA',
  'GJC2',
  'MTHFR',
  'MECP2',
  'PEX3',
  'HSD17B4',
  'HTR1A',
  'SMS',
  'COQ8A',
  'ZEB2',
  'GAA',
  'CAMTA1',
  'PKP2',
  'IL2RG',
  'MLC1',
  'IER3IP1',
  'MYH11',
  'TCF4',
  'ASAH1',
  'CLPB',
  'LAMA2',
  'PRKDC',
  'CASK',
  'OCA2',
  'ETFDH',
  'MT-CO1',
  'DEAF1',
  'POMC',
  'GPR56',
  'CTLA4',
  'AHI1',
  'MCCC1',
  'HIBCH',
  'RMRP',
  'PAK3',
  'CD3G',
  'KCNH2',
  'CBL',
  'ELANE',
  'TUBA8',
  'CYB5R3',
  'BRCA2',
  'SLC25A20',
  'ALDH18A1',
  'SETD5',
  'COL6A2',
  'COL6A3',
  'SLC35A2',
  'ATP6V0A2',
  'JUP',
  'NOD2',
  'CLN5',
  'MT-ATP6',
  'IL36RN',
  'AASS',
  'RTTN',
  'NEB',
  'SCN5A',
  'ELN',
  'DYRK1A',
  'KIF11',
  'SLC19A3',
  'LYST',
  'PIGO',
  'WFS1',
  'CLN3',
  'MYBPC3',
  'KIF4A',
  'PIGA',
  'PLP1',
  'ALG3',
  'ORAI1',
  'PTEN',
  'CSF1R',
  'DPM',
]

export const ACMG_INCIDENTAL_GENES = [
  'ACTA2',
  'ACTC1',
  'ACVRL1',
  'APC',
  'APOB',
  'ATP7B',
  'BMPR1A',
  'BRCA1',
  'BRCA2',
  'BTD',
  'CACNA1S',
  'CASQ2',
  'COL3A1',
  'DSC2',
  'DSG2',
  'DSP',
  'DSP',
  'ENG',
  'FBN1',
  'FLNC',
  'GAA',
  'GLA',
  'HFE',
  'HNF1A',
  'KCNH2',
  'KCNQ1',
  'LDLR',
  'LMNA',
  'MAX',
  'MEN1',
  'MLH1',
  'MSH2',
  'MSH6',
  'MUTYH',
  'MYBPC3',
  'MYH11',
  'MYH7',
  'MYH7',
  'MYL2',
  'MYL3',
  'NF2',
  'OTC',
  'PALB2',
  'PCSK9',
  'PKP2',
  'PMS2',
  'PRKAG2',
  'PTEN',
  'RB1',
  'RET',
  'RET',
  'RET',
  'RPE65',
  'RYR1',
  'RYR2',
  'SCN5A',
  'SCN5A',
  'SCN5A',
  'SDHAF2',
  'SDHB',
  'SDHC',
  'SDHD',
  'SMAD3',
  'SMAD4',
  'SMAD4',
  'STK11',
  'TGFBR1',
  'TGFBR2',
  'TMEM127',
  'TMEM43',
  'TNNI3',
  'TNNT2',
  'TNNT2',
  'TP53',
  'TPM1',
  'TRDN',
  'TRDN',
  'TSC1',
  'TSC2',
  'TTN',
  'VHL',
  'WT1',
]
