import { Card, Box, Divider } from '@material-ui/core'
import ChatMessageList from './ChatMessageList'
import ChatMessageInput from './ChatMessageInput'
import { useState, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useManageUser } from 'src/api/user/manage'

const NoteCard = ({ row, saveMessage }) => {
  const [tabPage, setTabPage] = useState(0)
  const { user } = useAuth()
  const { self, updateMutation } = useManageUser(user)
  const userDetails = self.data

  let data = row.note_list
  if (!data) {
    data = {
      messages: [],
    }
  }

  const handleSendMessage = ({ message }) => {
    const newData = {
      body: message,
      createdAt: new Date().toISOString(),
      sender: `${userDetails?.first_name} ${userDetails?.last_name}`,
    }

    const updatedData = {
      ...data,
      messages: [...(data.messages || []), newData],
    }
    // const updatedData = { messages: [] }

    saveMessage(row, updatedData)
  }

  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ marginLeft: 1, borderBottom: 1, borderColor: 'divider' }}>
        <ChatMessageList conversation={data} />
      </Box>
      <Divider />
      <Box sx={{ padding: 1 }}>
        <ChatMessageInput onSend={handleSendMessage} tabIndex={tabPage} />
      </Box>
    </Card>
  )
}

export default NoteCard
