import axios from '../../utils/axios'
import { API_BASE_URL } from '../../constants'

export const annotateFile = async (fileId, annotation, type) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/${type === 'VCF' ? 'vcf' : 'fastq'}/annotatev2`, {
      fileId,
      annotation,
    })
    return data
  } catch (error) {
    if (error.response) {
      if (error.response.status === 402) {
        return { error: 'Insufficient balance', needed_balance: error.response.data.needed_balance }
      }
      console.log(`Error: ${error.response.statusText}`)
    } else {
      console.log('Error: No response from server')
    }
    throw error
  }
}

export const getPlots = async (fileId) => {
  const { data } = await axios.get(`${API_BASE_URL}/fastq/plots/${fileId}`)

  return data
}

export const getSarekDashboard = async (fileId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/fastq/dashboard/${fileId}`)
    return response // Return the entire response object
  } catch (error) {
    console.error('Error fetching dashboard:', error)
    throw error // Rethrow the error to be handled by the caller
  }
}
