// TODO: use mutation
import useOptimisticQuery from '../../hooks/useOptimisticQuery'
import axios from '../../utils/axios'
import { API_BASE_URL, HPO_MAP } from '../../constants'

export const updateHpo = async (fileId, hpoIdList) => {
  const { data } = await axios.patch(`${API_BASE_URL}/vcf/${fileId}/hpo`, {
    file_id: fileId,
    hpo_id_list: [],
    hpo_list_with_user_data: hpoIdList,
  })

  return data
}

const fetchHpo = async (fileId) => {
  const { data } = await axios.get(`${API_BASE_URL}/vcf/${fileId}/hpo`, {
    file_id: fileId,
  })
  return data
}

export const useHpo = ({ fileId }) => {
  const [hpoIdList = [], setHpoIdList] = useOptimisticQuery({
    queryKey: ['hpo-id-list', fileId],
    queryFn: () => fetchHpo(fileId),
    mutationFn: (hpoIdList) => updateHpo(fileId, hpoIdList),
    delay: 0,
  })

  let hpoList = hpoIdList

  const setHpoList = (newHpoList) => {
    const newIds = newHpoList
    setHpoIdList(newIds)
  }

  return [hpoList, setHpoList]
}
