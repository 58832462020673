import { isString } from 'lodash'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useDropzone } from 'react-dropzone'
import fileFill from '@iconify/icons-eva/file-fill'
import closeFill from '@iconify/icons-eva/close-fill'
import { motion, AnimatePresence } from 'framer-motion'
// material
import { alpha, styled } from '@material-ui/core/styles'
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
// utils
import { fData } from '../../utils/formatNumber'
//
import { MIconButton } from '../@material-extend'
import { varFadeInRight } from '../animate'
import { UploadIllustration } from '../../assets'

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
}))

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object,
  onUploadFiles: PropTypes.func,
  locked: PropTypes.bool,
}

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  onUploadFiles,
  locked = false,
  sx,
  onCancel,
  minimal = false,
  NamingComponent = null,
  fileNames = [],
  setFileNames = () => {},
  references = [],
  setReferences = () => {},
  ...other
}) {
  const hasFile = files.length > 0

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  })

  const ShowRejectionItems = function () {
    return (
      <Paper
        variant="outlined"
        sx={{
          py: 1,
          px: 2,
          mt: 3,
          borderColor: 'error.light',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        }}
      >
        {fileRejections.map(({ file, errors }) => {
          const { path, size } = file
          return (
            <Box key={path} sx={{ my: 1 }}>
              <Typography variant="subtitle2" noWrap>
                {path} - {fData(size)}
              </Typography>
              {errors.map((e) => (
                <Typography key={e.code} variant="caption" component="p">
                  - {e.message}
                </Typography>
              ))}
            </Box>
          )
        })}
      </Paper>
    )
  }

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          height: minimal ? '50px' : '100%',
        }}
      >
        <input {...getInputProps()} />

        {!minimal && <UploadIllustration sx={{ width: 220 }} />}

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          {!minimal && (
            <Typography gutterBottom variant="h5">
              Drop or Select file
            </Typography>
          )}

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Drop files here or click&nbsp;
            <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
              browse
            </Typography>
            &nbsp;through your machine
          </Typography>
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}

      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files?.map((file, index) => {
            const { name, size, preview } = file

            if (showPreview) {
              return (
                <ListItem
                  key={`key-${index}`}
                  component={motion.div}
                  {...varFadeInRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.5,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex',
                  }}
                >
                  <Paper
                    variant="outlined"
                    component="img"
                    src={isString(file) ? file : preview}
                    sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                  />
                  <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                    <MIconButton
                      size="small"
                      onClick={() => onRemove(file)}
                      sx={{
                        p: '2px',
                        color: 'common.white',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        '&:hover': {
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                        },
                      }}
                    >
                      <Icon icon={closeFill} />
                    </MIconButton>
                  </Box>
                </ListItem>
              )
            }

            return (
              <Box key={`uploadbox-${index}`}>
                <ListItem
                  key={`upload-${index}`}
                  component={motion.div}
                  {...varFadeInRight}
                  sx={{
                    my: 1,
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper',
                  }}
                >
                  <ListItemIcon>
                    <Icon icon={fileFill} width={28} height={28} />
                  </ListItemIcon>
                  <ListItemText
                    primary={isString(file) ? file : name}
                    secondary={isString(file) ? '' : fData(size)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>
                    {!locked && (
                      <MIconButton edge="end" size="small" onClick={() => onRemove(file)}>
                        <Icon icon={closeFill} />
                      </MIconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                {NamingComponent &&
                  NamingComponent({
                    file: file,
                    index: index,
                    fileNames: fileNames,
                    setFileNames: setFileNames,
                    references: references,
                    setReferences: setReferences,
                  })}
              </Box>
            )
          })}
        </AnimatePresence>
      </List>

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={onCancel} sx={{ mr: 1.5 }} disabled={!locked}>
            Cancel
          </Button>
          <Button onClick={onRemoveAll} sx={{ mr: 1.5 }} disabled={locked}>
            Remove all
          </Button>
          <Button variant="contained" onClick={() => onUploadFiles(files)} disabled={locked}>
            Upload files
          </Button>
        </Stack>
      )}
    </Box>
  )
}
