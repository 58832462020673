import homeFill from '@iconify/icons-eva/home-fill'
import roundGrain from '@iconify/icons-ic/round-grain'
import { Icon } from '@iconify/react'
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths'

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
}

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Genesus™',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: PATH_DASHBOARD.general.files,
  },
  { title: 'Research', path: PATH_PAGE.comingSoon },
  { title: 'About Us', path: PATH_PAGE.about },
  /*
    {
      title: 'Company',
      path: '/pages',
      icon: <Icon icon={fileFill} {...ICON_SIZE} />,
      children: [
        { title: 'About us', path: PATH_PAGE.about },
        { title: 'Contact us', path: PATH_PAGE.contact },
        { title: 'FAQs', path: PATH_PAGE.faqs },
        { title: 'Pricing', path: PATH_PAGE.pricing },
        { title: 'Payment', path: PATH_PAGE.payment },
        { title: 'Maintenance', path: PATH_PAGE.maintenance },
        { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
      ],
    },
    
   */
]

export default menuConfig

/*
  {
    title: 'Company',
    path: '/pages',
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Dashboard',
        items: [{ title: 'Dashboard', path: PATH_DASHBOARD.root }],
      },
    ],
  },
 */
