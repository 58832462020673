import PropTypes from 'prop-types'
import { formatDistanceToNow } from 'date-fns'
// material
import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}))

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}))

const MessageImgStyle = styled('img')(({ theme }) => ({
  height: 200,
  minWidth: 296,
  width: '100%',
  cursor: 'pointer',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
}))

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  //   conversation: PropTypes.object.isRequired,
  onOpenLightbox: PropTypes.func,
}

export default function ChatMessageItem({ message, onOpenLightbox, ...other }) {
  //   const sender = conversation.participants.find((participant) => participant.id === message.senderId)
  const senderDetails = message.sender
  const isImage = message.contentType === 'image'

  return (
    <RootStyle {...other}>
      <Box
        sx={{
          display: 'flex',
          ...{
            ml: 'auto',
          },
        }}
      >
        <Box sx={{ ml: 2 }}>
          <InfoStyle noWrap variant="caption" sx={{ ...{ justifyContent: 'flex-end' } }}>
            {`${senderDetails}, ${new Date(message.createdAt).toLocaleString('en-UK', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })}`}
          </InfoStyle>

          <ContentStyle
            sx={{
              ...{
                color: 'grey.800',
                bgcolor: 'primary.lighter',
              },
            }}
          >
            {isImage ? (
              <MessageImgStyle alt="attachment" src={message.body} onClick={() => onOpenLightbox(message.body)} />
            ) : (
              <Typography variant="body2">{message.body}</Typography>
            )}
          </ContentStyle>
        </Box>
      </Box>
    </RootStyle>
  )
}
