import {
  Box,
  CircularProgress,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Chip,
  IconButton,
  DialogContentText,
  Typography,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ArrowForward, Info, Note, CloudDownload } from '@material-ui/icons'
// genesus/frontend/src/utils/axios
import axios from '../../utils/axios'
import MUIDataTable from 'mui-datatables'
import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import { fDateTime } from 'src/utils/formatTime'
import JobStateStatus from './JobStateStatus'
import { deleteVcfFile } from '../../api/vcf'
import { deleteFastqFile } from '../../api/fastq'
import { useFiles, annotateFile, useBedFiles, updateFinishInfo, updateFileNotes } from '../../api/file'
import Snackbar from '@mui/material/Snackbar'

import ExpandOnClick from 'src/components/ExpandOnClick'
import AnalysedCheckbox from './AnalysedCheckbox'

import { NewAnnotationDialog } from './AnnotationDialog'
import ClickAwayTooltip from 'src/components/ClickAwayTooltip'
import NoteCard from './chat/NoteCard'
import { API_BASE_URL } from '../../constants'

import AnalysisPopover from './AnalysisPopover'

const EditableNote = ({ note, onSave, details }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentNote, setCurrentNote] = useState(note)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    onSave(currentNote)
    setIsEditing(false)
  }

  const handleChange = (e) => {
    setCurrentNote(e.target.value)
  }

  return (
    <Box p={2}>
      {isEditing ? (
        <Box>
          <TextField multiline value={currentNote} onChange={handleChange} />
          <Box p={1} />
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography>{currentNote}</Typography>
          <Divider />
          {details.date && details.person && (
            <Typography variant="caption">
              {details.date && fDateTime(details.date)} {details.person ? `by ${details.person}` : null}
            </Typography>
          )}
          <Box p={1} />
          <Button variant="contained" onClick={handleEdit}>
            Edit
          </Button>
        </Box>
      )}
    </Box>
  )
}

const getStatusLabel = (row) => {
  const analyzeStatus = row?.analyses?.status ? row.analyses.status : null
  const annotationStatus = row?.annotations?.status ? row.annotations.status : null
  const is_annotated = row?.is_annotated
  if (!is_annotated) return 'WAITING'
  // if we're finished with annotating, return analysis status
  if (!annotationStatus || annotationStatus === 'DONE') return analyzeStatus
  return 'ANNO_' + annotationStatus
}

const useStyles = makeStyles((theme) => ({
  expandedCell: {
    boxShadow: theme.shadows[3]
      .split('),')
      .map((s) => `inset ${s}`)
      .join('),'),
  },
}))

const DeleteFileButton = ({ onClickConfirm }) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleClickDelete = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleClickConfirm = () => {
    setDialogOpen(false)
    onClickConfirm()
  }

  return (
    <>
      <IconButton sx={{ '&:hover': { color: 'error.main' } }} onClick={handleClickDelete}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Danger: Delete file</DialogTitle>
        <Box p={0.5} />
        <DialogContent>
          <DialogContentText>Do you really want to delete this file?</DialogContentText>
          <DialogContentText>This will delete the file and all associated analyses.</DialogContentText>
          <DialogContentText>
            <strong>This action cannot be undone.</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleClickConfirm} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const GoToSampleDashboard = function ({ fileId, sampleName, fastqPairId }) {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/libra/sample/${fileId}/${sampleName}`, {
      state: { fastqPairId },
    })
  }

  return (
    <Button variant="contained" onClick={handleClick} size="small">
      <ArrowForward />
    </Button>
  )
}

const SamplesView = function () {
  //const classes = useStyles()
  const filesApi = useFiles()
  const bedFilesApi = useBedFiles()
  const { status, data = [] } = filesApi.query
  const { data: bedFiles = [] } = bedFilesApi.query
  const [isAnnotationModalOpen, setAnnotationModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [neededBalance, setNeededBalance] = useState(-1)
  const [paymentSnackbarOpen, setPaymentSnackbarOpen] = useState(false)
  const [deductedAmount, setDeductedAmount] = useState(-1)
  const [newBalance, setNewBalance] = useState(-1)

  const setFinishedInfo = (row) => {
    const id = row.fastq_pair_id ? row.fastq_pair_id : row.vcf_id
    updateFinishInfo(id).then(() => {
      filesApi.refresh()
    })
  }

  const setFileNotes = (row, notes) => {
    const id = row.fastq_pair_id ? row.fastq_pair_id : row.vcf_id
    updateFileNotes(id, notes).then(() => {
      filesApi.refresh()
    })
  }

  const handleDownloadRawVcf = async (vcfId, sampleName) => {
    // if sampleName includes .gz, remove the .gz
    sampleName = sampleName.replace('.gz', '')
    sampleName = sampleName.replace('.vcf', '')
    sampleName = sampleName.replace('.raw', '')
    // add .vcf.gz
    sampleName = sampleName + '.vcf.gz'
    await axios
      .get(`${API_BASE_URL}/vcf/downloadrawvcf/${vcfId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]))
        // Create a temporary anchor element
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        // Set the file name
        a.download = `${sampleName}`
        // Append to the body and click
        document.body.appendChild(a)
        a.click()
        // Clean up
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => {
        console.error('Error downloading the file:', error)
        // Handle the error (e.g., show an error message to the user)
      })
  }

  const handleFileAnnotation = (annotation) => {
    const { id, type } = selectedFile?.fastq_pair_id
      ? { id: selectedFile.fastq_pair_id, type: 'FASTQ' }
      : { id: selectedFile.vcf_id, type: 'VCF' }

    annotateFile(id, annotation, type).then((res) => {
      if (res?.error === 'Insufficient balance') {
        setErrorModalOpen(true)
        setNeededBalance(res?.needed_balance)
      } else {
        filesApi.refresh()
        setAnnotationModalOpen(false)
        setPaymentSnackbarOpen(true)
        setNewBalance(res?.new_balance)
        setDeductedAmount(res?.deducted_amount)
      }
    })
  }

  const handleAnnotationModelOpen = (row) => {
    setSelectedFile(row)
    setAnnotationModalOpen(true)
  }

  const COLUMNS = [
    {
      name: 'dashboard',
      label: 'Dashboard',

      options: {
        setCellProps: () => ({
          style: { maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
        }),
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex]
          const status = getStatusLabel(row)

          if (status !== 'DONE') {
            return <JobStateStatus status={status} />
          }
          return (
            <GoToSampleDashboard
              // fileId={row.fastq_pair_id ? row.fastq_pair_id : row.vcf_id}
              fileId={row.vcf_id}
              sampleName={row.sample_name}
              fastqPairId={row.fastq_pair_id ? row.fastq_pair_id : null}
            />
          )
        },
      },
    },
    {
      name: 'analysis',
      label: 'Analysis',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex]
          const status = getStatusLabel(row)
          const id = row.vcf_id

          return status ? (
            status === 'DONE' ? (
              <AnalysisPopover fileId={id} sampleName={row.sample_name} />
            ) : status.includes('RUNNING') ? null : (
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="info" onClick={() => handleAnnotationModelOpen(row)} size="small">
                  <Info />
                </Button>
              </Stack>
            )
          ) : null
        },
      },
    },
    {
      name: 'name',
      label: 'Filename',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex]
          if (!row) return null
          if (row?.fastq_pair_id) {
            return (
              <Stack direction="column" spacing={1} alignItems="flex-start">
                <Chip label={row.fastq_file_1.name} />
                <Chip label={row.fastq_file_2.name} />
              </Stack>
            )
          }
          return <Chip label={row.name} />
        },
      },
    },
    {
      name: 'downloadRawFile',
      label: 'Download Raw File',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex]
          if (!row) return null
          if (row?.fastq_pair_id) {
            // inform the user that fastq files are not downloadable
            return <Chip label="Not a VCF file" />
          }
          return (
            <Tooltip title="Download Raw VCF">
              <IconButton onClick={() => handleDownloadRawVcf(row.vcf_id, row.name)}>
                <CloudDownload />
              </IconButton>
            </Tooltip>
          )
        },
      },
    },
    {
      name: 'run_type',
      label: 'Run Type',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex]
          if (!row) return null
          if (row?.fastq_pair_id) {
            const status = getStatusLabel(row)
            if (!row.sarek_run_type) return <Chip label={'FASTQ Analysis'} />
            const runTypeMapping = {
              WholeGenomeGermline: ['Whole Genome + Germline', 'WGS-G'],
              WholeGenomeTumorOnly: ['Whole Genome + Tumor Only', 'WGS-TO'],
              WholeGenomeTumorNormal: ['Whole Genome + Tumor Normal', 'WGS-TN'],
              WholeExomeGermline: ['Whole Exome + Germline', 'WES-G'],
              WholeExomeTumorOnly: ['Whole Exome + Tumor Only', 'WES-TO'],
              WholeExomeTumorNormal: ['Whole Exome + Tumor Normal', 'WES-TN'],
              ClinicalExomeGermline: ['Clinical Exome + Germline', 'CES-G'],
              ClinicalExomeTumorOnly: ['Clinical Exome + Tumor Only', 'CES-TO'],
              ClinicalExomeTumorNormal: ['Clinical Exome + Tumor Normal', 'CES-TN'],
            }
            var runType = row.sarek_run_type ? runTypeMapping[row.sarek_run_type][0] : 'FASTQ Analysis'
            var seenRunType = row.sarek_run_type ? runTypeMapping[row.sarek_run_type][1] : 'FASTQ Analysis'
            var bedName = row.sarek_bed_name ? row.sarek_bed_name : ''
            var normalPairName =
              row.normal_pair_1_name && row.normal_pair_2_name
                ? row.normal_pair_1_name + ' ' + row.normal_pair_2_name
                : null
            const realRunType = (
              <Typography variant="body1" sx={{ p: 0.5 }}>
                <>
                  {runType}
                  {normalPairName && (
                    <>
                      <br /> Normal Sample: <br /> {row.normal_pair_1_name}
                      <br />
                      {row.normal_pair_2_name}
                    </>
                  )}
                </>
              </Typography>
            )
            const realBedName = (
              <Typography variant="body1" sx={{ p: 0.5 }}>
                {bedName}
              </Typography>
            )
            const realNormalPairName = (
              <Typography variant="body1" sx={{ p: 0.5 }}>
                {row.normal_pair_1_name}
                <br />
                {row.normal_pair_2_name}
              </Typography>
            )
            return (
              <>
                <Stack direction="column" spacing={1}>
                  <ClickAwayTooltip title={realRunType} placement="top" interactive>
                    <Chip label={seenRunType} />
                  </ClickAwayTooltip>
                  {bedName !== 'WholeGenome' && (
                    <ClickAwayTooltip title={realBedName} placement="top" interactive>
                      <Chip label={'Covered Targets'} />
                    </ClickAwayTooltip>
                  )}
                  {/* {normalPairName && (
                    <ClickAwayTooltip title={realNormalPairName} placement="top" interactive>
                      <Chip label={'Normal Sample'} />
                    </ClickAwayTooltip>
                  )} */}
                </Stack>
              </>
            )
          }
          return <Chip label={'VCF Analysis'} />
        },
      },
    },
    {
      name: 'sample_name',
      label: 'Sample',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          const sampleName = row?.sample_name

          return sampleName ? (
            <Tooltip title={sampleName.length > 20 ? sampleName : ''}>
              <Typography>{sampleName.length > 20 ? `${sampleName.slice(0, 20)}...` : sampleName}</Typography>
            </Tooltip>
          ) : null
        },
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]

          return row ? (
            <Stack direction="row" spacing={1}>
              <ExpandOnClick expanded={<NoteCard row={row} saveMessage={setFileNotes} />}>
                {({ ref, onClick }) => (
                  <IconButton variant="contained" ref={ref} onClick={onClick}>
                    <Note />
                  </IconButton>
                )}
              </ExpandOnClick>
              <AnalysedCheckbox
                checked={row.is_finished}
                onChange={(e) => setFinishedInfo(row)}
                details={{ date: row.finish_time, person: row.finish_person }}
              />
            </Stack>
          ) : null
        },
      },
    },
    {
      name: 'created_at',
      label: 'Uploaded At',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          return row ? fDateTime(row.created_at) : null
        },
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          if (!row) return null

          const handleClickConfirm = () => {
            if (!row.fastq_pair_id && row.vcf_id) {
              deleteVcfFile(row.vcf_id)
            } else if (row.fastq_pair_id) {
              deleteFastqFile(row.fastq_file_1.fastq_file_id)
              deleteFastqFile(row.fastq_file_2.fastq_file_id)
            } else {
              deleteFastqFile(row.fastq_file_id)
            }

            filesApi.refresh()
          }

          return <DeleteFileButton onClickConfirm={handleClickConfirm} />
        },
      },
    },
  ]

  switch (status) {
    case 'success':
      return (
        <>
          {/* <AnnotationDialog
            open={isAnnotationModalOpen}
            onClose={() => setAnnotationModalOpen(false)}
            onClickAnnotate={handleFileAnnotation}
            fileType={selectedFile?.fastq_pair_id ? 'FASTQ' : 'VCF'}
            title={selectedFile?.sample_name}
            bedFiles={bedFiles}
            bedFilesApi={bedFilesApi}
            key={'create-analysis-dialog'}
          /> */}
          <NewAnnotationDialog
            open={isAnnotationModalOpen}
            onClose={() => setAnnotationModalOpen(false)}
            onClickAnnotate={handleFileAnnotation}
            fileType={selectedFile?.fastq_pair_id ? 'FASTQ' : 'VCF'}
            title={selectedFile?.sample_name}
            bedFiles={bedFiles}
            bedFilesApi={bedFilesApi}
            key={'create-analysis-dialog'}
            data={data}
            currentId={selectedFile?.fastq_pair_id ? selectedFile?.fastq_pair_id : selectedFile?.vcf_id}
          />
          <MUIDataTable
            title="Files"
            data={data}
            columns={COLUMNS}
            options={{
              selectableRows: 'none',
              sortOrder: { name: 'created_at', direction: 'desc' },
              expandableRows: false,
              print: false,
              viewColumns: true,
              download: false,
            }}
          />
          <Dialog open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
            <>
              <DialogTitle>Insufficient Balance</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  You do not have enough balance to perform the annotation, please increase your balance by{' '}
                  {neededBalance}.
                </DialogContentText>
              </DialogContent>
            </>
          </Dialog>
          <Snackbar
            open={paymentSnackbarOpen}
            autoHideDuration={4000}
            onClose={() => {
              setPaymentSnackbarOpen(false)
            }}
            message={`${deductedAmount} was deducted. Your new balance is ${newBalance}`}
          />
        </>
      )
    default:
      return <CircularProgress />
  }
}

export default SamplesView
