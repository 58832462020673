import { Box, Checkbox, Typography, Tooltip } from '@material-ui/core'
import { fDateTime } from 'src/utils/formatTime'

function AnalysedCheckbox({ checked, onChange, details, user, ...rest }) {
  const tooltipTitle = checked ? `${details.person} | ${fDateTime(details.date)}` : ''
  return (
    <Tooltip title={tooltipTitle}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Checkbox checked={checked} onChange={onChange} {...rest} />
      </Box>
    </Tooltip>
  )
}

export default AnalysedCheckbox
