import React, { createContext, useContext, useState } from 'react'

const NavbarContext = createContext()

export function useNavbarContext() {
  return useContext(NavbarContext)
}

export function NavbarProvider({ children }) {
  const [isSelectedVariantsMode, setIsSelectedVariantsMode] = useState(false)
  const [isHpoPanelOpen, setIsHpoPanelOpen] = useState(false)
  const [isToolbarPanelOpen, setIsToolbarPanelOpen] = useState(false)
  const [sampleName, setSampleName] = useState(null)
  const [fileId, setFileId] = useState(null)
  const [handleCreatePdfFunc, setHandleCreatePdfFunc] = useState(() => () => {})

  const value = {
    isSelectedVariantsMode,
    setIsSelectedVariantsMode,
    isHpoPanelOpen,
    setIsHpoPanelOpen,
    isToolbarPanelOpen,
    setIsToolbarPanelOpen,
    sampleName,
    setSampleName,
    handleCreatePdfFunc,
    setHandleCreatePdfFunc,
    fileId,
    setFileId,
  }

  return <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
}
