import menu2Fill from '@iconify/icons-eva/menu-2-fill'
import { Icon } from '@iconify/react'
import { AppBar, Box, IconButton, Stack, Toolbar, Button, Divider } from '@material-ui/core'
// material
import { alpha, styled } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
// components
import Logo from '../../components/Logo'
import { MHidden } from '../../components/@material-extend'
import { PATH_DASHBOARD } from '../../routes/paths'
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer'
import AccountPopover from './AccountPopover'
import { useUserList, useOrganisationDetails } from '../../api/user/list'
import { useManageUser } from 'src/api/user/manage'
import { Typography } from '@material-ui/core'
import peopleFill from '@iconify/icons-eva/people-fill'
import useAuth from '../../hooks/useAuth'
import personFill from '@iconify/icons-eva/person-fill'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import PersonIcon from '@mui/icons-material/Person'
import { NavLink } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import MenuPopover from 'src/components/MenuPopover'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNavbarContext } from 'src/contexts/NavbarContext'
import FilterListIcon from '@material-ui/icons/FilterList'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import BallotIcon from '@material-ui/icons/Ballot'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded'
import NavbarRoutes from 'src/views/VariantsView/NavbarRoutes'
import { NAVBAR_CONFIG } from 'src/constants'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280
const COLLAPSE_WIDTH = 0 // change if sidebar is added

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
  },
}))

const NAVBAR_OPTIONS = [
  {
    label: 'Upload/Files',
    linkTo: PATH_DASHBOARD.general.files,
  },
  // TODO: Don't show users if the user is not admin
  {
    label: 'Kits/Panels',
    linkTo: PATH_DASHBOARD.general.kits,
  },
  {
    label: 'Reports',
    linkTo: PATH_DASHBOARD.general.reports,
  },
  {
    label: 'Search',
    linkTo: PATH_DASHBOARD.general.search,
  },
]

const VARIANT_DASHBOARD = {
  label: 'Variant Dashboard',
  linkto: PATH_DASHBOARD.general.variantDashboard,
}

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}

export default function DashboardNavbar({ onOpenSidebar }) {
  // const ss = useParams()
  const location = useLocation()
  const [inVariantsPage, setInVariantsPage] = useState(false)
  const { isCollapse } = useCollapseDrawer()
  var organisationDetails = useOrganisationDetails()
  organisationDetails = organisationDetails.data || {}
  const { user } = useAuth()
  const { self, updateMutation } = useManageUser(user)
  const userDetails = self.data

  useEffect(() => {
    const variantPagePattern = /^\/libra\/variants\/.+$/

    if (variantPagePattern.test(location.pathname)) {
      setInVariantsPage(true)
    } else {
      setInVariantsPage(false)
    }
  }, [location])

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
        <Box ml={2} />

        {/* <Searchbar /> */}
        {/* <Box id="custom-toolbar-container" display="flex" justifyContent="center" alignItems="center" /> */}

        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          {inVariantsPage ? (
            <>
              <NavbarMenuPopover />
              <VariantPageNavbar />
            </>
          ) : (
            NAVBAR_OPTIONS.map((option) => (
              <NavLink
                key={option.label}
                to={option.linkTo}
                style={{ color: '#212B36' }}
                activeStyle={{ color: '#00AB55' }}
              >
                <Button sx={{ color: 'inherit' }}>{option.label}</Button>
              </NavLink>
            ))
          )}
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <PersonIcon sx={{ mr: 1, color: 'text.primary' }} />
            <Typography variant="body2" color="text.primary">
              {userDetails?.first_name || ''} {userDetails?.last_name || ''}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <CorporateFareIcon sx={{ mr: 1, color: 'text.primary' }} />
            <Typography variant="body2" color="text.primary">
              {organisationDetails.name}
            </Typography>
          </Box>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  )
}

const NavbarMenuPopover = () => {
  const anchorRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)

  const handleOpenMenu = () => {
    setOpenMenu(true)
  }

  const handleCloseMenu = () => {
    setOpenMenu(false)
  }

  return (
    <>
      <IconButton onClick={handleOpenMenu} ref={anchorRef}>
        <ExpandMoreIcon></ExpandMoreIcon>
      </IconButton>
      <MenuPopover
        open={openMenu}
        onClose={handleCloseMenu}
        anchorEl={anchorRef.current}
        sx={{ width: 100, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {NAVBAR_OPTIONS.map((option) => (
          <NavLink
            key={option.label}
            to={option.linkTo}
            style={{ color: '#212B36' }}
            activeStyle={{ color: '#00AB55' }}
          >
            <Button sx={{ color: 'inherit' }}>{option.label}</Button>
          </NavLink>
        ))}
      </MenuPopover>
    </>
  )
}

const VariantPageNavbar = () => {
  const {
    isSelectedVariantsMode,
    setIsSelectedVariantsMode,
    setIsHpoPanelOpen,
    setIsToolbarPanelOpen,
    handleCreatePdfFunc,
    sampleName,
    fileId,
  } = useNavbarContext()

  return (
    <Stack direction="row" spacing={2}>
      {sampleName && (
        <>
          <NavbarRoutes navConfig={NAVBAR_CONFIG} params={{ fileId, sampleName }} />
          <Button
            variant="text"
            color="error"
            startIcon={isSelectedVariantsMode ? <ArrowBackIosIcon /> : <BallotIcon />}
            onClick={() => setIsSelectedVariantsMode(!isSelectedVariantsMode)}
          >
            {isSelectedVariantsMode ? 'Back' : 'Selected Variants'}
          </Button>
          {!isSelectedVariantsMode && (
            <>
              <Divider orientation="vertical" flexItem />
              <Button
                variant="text"
                color="secondary"
                startIcon={<AccountTreeRoundedIcon />}
                onClick={() => setIsHpoPanelOpen(true)}
              >
                HPO
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                variant="text"
                color="info"
                startIcon={<FilterListIcon />}
                onClick={() => setIsToolbarPanelOpen(true)}
              >
                Filters
              </Button>
            </>
          )}
          {isSelectedVariantsMode && (
            <>
              <Divider orientation="vertical" flexItem />
              <Button variant="text" color="warning" startIcon={<PictureAsPdfIcon />} onClick={handleCreatePdfFunc}>
                Create Report
              </Button>
            </>
          )}
        </>
      )}
    </Stack>
  )
}
