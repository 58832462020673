import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Chip,
  IconButton,
  DialogContentText,
  Typography,
  TextField,
  Stack,
} from '@material-ui/core'
import { Note } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import React, { useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { annotateFile, updateFileNotes } from '../../api/file'
import ExpandOnClick from 'src/components/ExpandOnClick'
import { NewAnnotationDialog } from './AnnotationDialog'
import { fDateTime } from 'src/utils/formatTime'

const EditableNote = ({ note, onSave, details }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentNote, setCurrentNote] = useState(note)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    onSave(currentNote)
    setIsEditing(false)
  }

  const handleChange = (e) => {
    setCurrentNote(e.target.value)
  }

  return (
    <Box p={2}>
      {isEditing ? (
        <Box>
          <TextField multiline value={currentNote} onChange={handleChange} />
          <Box p={1} />
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography>{currentNote}</Typography>
          <Divider />
          {details.date && details.person && (
            <Typography variant="caption">
              {details.date && fDateTime(details.date)} {details.person ? `by ${details.person}` : null}
            </Typography>
          )}
          <Box p={1} />
          <Button variant="contained" onClick={handleEdit}>
            Edit
          </Button>
        </Box>
      )}
    </Box>
  )
}

const DeleteFileButton = ({ onClickConfirm }) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleClickDelete = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleClickConfirm = () => {
    setDialogOpen(false)
    onClickConfirm()
  }

  return (
    <>
      <IconButton sx={{ '&:hover': { color: 'error.main' } }} onClick={handleClickDelete}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Danger: Delete file</DialogTitle>
        <Box p={0.5} />
        <DialogContent>
          <DialogContentText>Do you really want to delete this file?</DialogContentText>
          <DialogContentText>This will delete the file and all associated analyses.</DialogContentText>
          <DialogContentText>
            <strong>This action cannot be undone.</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleClickConfirm} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const SamplesView = ({ isKits }) => {
  const { status, data = [] } = { status: 'success', data: [] }
  const [isAnnotationModalOpen, setAnnotationModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const titleToShow = isKits ? 'Capture Kits' : 'Gene Panels'

  const setFileNotes = (row, notes) => {
    const id = row.id
    updateFileNotes(id, notes).then(() => {
      // Refresh logic here if needed
    })
  }

  const handleFileAnnotation = (annotation) => {
    const { id, type } = selectedFile
    annotateFile(id, annotation, type).then((res) => {
      // Refresh logic here if needed
      setAnnotationModalOpen(false)
    })
  }

  const handleAnnotationModelOpen = (row) => {
    setSelectedFile(row)
    setAnnotationModalOpen(true)
  }

  const KITS_COLUMNS = [
    {
      name: 'delete',
      label: 'Delete',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          if (!row) return null

          const handleClickConfirm = () => {
            // Delete logic here
            // Refresh logic here if needed
          }

          return <DeleteFileButton onClickConfirm={handleClickConfirm} />
        },
      },
    },
    {
      name: 'created_at',
      label: 'Uploaded At',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          return row ? fDateTime(row.created_at) : null
        },
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          return row ? (
            <ExpandOnClick
              expanded={
                <EditableNote
                  note={row.file_notes}
                  onSave={(notes) => setFileNotes(row, notes)}
                  details={{ date: row.notes_time, person: row.notes_person }}
                />
              }
            >
              {({ ref, onClick }) => (
                <IconButton variant="contained" ref={ref} onClick={onClick}>
                  <Note />
                </IconButton>
              )}
            </ExpandOnClick>
          ) : null
        },
      },
    },
    {
      name: 'name',
      label: 'Filename',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex]
          if (!row) return null
          return <Chip label={row.name} />
        },
      },
    },
    {
      name: 'reference',
      label: 'Reference',
      options: {},
    },
  ]

  const PANELS_COLUMNS = [
    {
      name: 'delete',
      label: 'Delete',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          if (!row) return null

          return <DeleteFileButton />
        },
      },
    },
    {
      name: 'created_at',
      label: 'Uploaded At',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          return row ? fDateTime(row.created_at) : null
        },
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          const row = data[dataIndex]
          return row ? (
            <ExpandOnClick
              expanded={
                <EditableNote
                  note={row.file_notes}
                  onSave={(notes) => setFileNotes(row, notes)}
                  details={{ date: row.notes_time, person: row.notes_person }}
                />
              }
            >
              {({ ref, onClick }) => (
                <IconButton variant="contained" ref={ref} onClick={onClick}>
                  <Note />
                </IconButton>
              )}
            </ExpandOnClick>
          ) : null
        },
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const row = data[dataIndex]
          if (!row) return null
          if (row?.fastq_pair_id) {
            return (
              <Stack direction="row" spacing={1}>
                <Chip label={row.fastq_file_1.name} />
                <Chip label={row.fastq_file_2.name} />
              </Stack>
            )
          }
          return <Chip label={row.name} />
        },
      },
    },
    {
      name: 'genes',
      label: 'Genes',
      options: {},
    },
  ]

  return (
    <>
      <NewAnnotationDialog
        open={isAnnotationModalOpen}
        onClose={() => setAnnotationModalOpen(false)}
        onClickAnnotate={handleFileAnnotation}
        fileType={selectedFile?.type}
        title={selectedFile?.sample_name}
        bedFiles={data}
        key={'create-analysis-dialog'}
        data={data}
        currentId={selectedFile?.id}
      />
      <MUIDataTable
        title={titleToShow}
        data={data}
        columns={isKits ? KITS_COLUMNS : PANELS_COLUMNS}
        options={{
          selectableRows: 'none',
          sortOrder: { name: 'created_at', direction: 'desc' },
          expandableRows: false,
          print: false,
          viewColumns: true,
          download: false,
        }}
      />
    </>
  )
}

export default SamplesView
