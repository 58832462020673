// ----------------------------------------------------------------------

const TRANSITION = {
  duration: 5,
  ease: 'linear',
}

export const varColor2x = {
  animate: {
    background: ['#19dcea', '#b22cff'],
    transition: TRANSITION,
  },
}

export const varColor3x = {
  animate: {
    background: ['#19dcea', '#b22cff', '#ea2222'],
    transition: TRANSITION,
  },
}

export const varColor4x = {
  animate: {
    background: ['#19dcea', '#b22cff', '#ea2222', '#f5be10'],
    transition: TRANSITION,
  },
}

export const varColor5x = {
  animate: {
    background: ['#19dcea', '#b22cff', '#ea2222', '#f5be10', '#3bd80d'],
    transition: TRANSITION,
  },
}
