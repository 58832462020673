import {
  Box,
  Button,
  CardHeader,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Checkbox,
  Stack,
  Tooltip,
} from '@material-ui/core'
import { UploadMultiFile } from 'src/components/upload'
import { useState, useEffect } from 'react'

import axios from 'axios'
import { uploadFile } from '../../api/bed'

let cancelTokenSource = null

export const NewAnnotationDialog = function ({
  open,
  onClose,
  onClickAnnotate,
  fileType,
  title,
  bedFiles,
  bedFilesApi,
  data,
  currentId,
}) {
  const [type, setType] = useState('WholeGenome') // Can be WholeGenome or Targeted
  const [targetedType, setTargetedType] = useState('WholeExome') // Can be  '' or WholeExome or ClinicalExome
  const [reference, setReference] = useState('GRCh38')
  const [analysisType, setAnalysisType] = useState('Germline')

  const [bedUploadOpen, setBedUploadOpen] = useState(false)
  const [fileQueue, setFileQueue] = useState([])
  const [state, setState] = useState('IDLE')
  const [uploadProgress, setUploadProgress] = useState(0)

  const [fileNames, setFileNames] = useState([])
  const [references, setReferences] = useState([])
  const [normalSample, setNormalSample] = useState('')

  const filteredData = data.filter(
    (item) =>
      item.fastq_pair_id &&
      item.fastq_file_1 &&
      item.fastq_file_2 &&
      // !item.is_annotated &&
      item.fastq_pair_id !== currentId,
  )

  const defaultKit = {
    created: '2024-01-01T00:00:00.900201Z',
    id: null,
    is_default: true,
    modified: '2024-01-01T00:00:00.900201Z',
    name: 'hglft_genome_64dc_dcbaa0.bed',
    original_name: '(Default) hglft_genome_64dc_dcbaa0.bed',
    person_id: null,
    reference: 'GRCh37',
    visible_name: '(Default) hglft_genome_64dc_dcbaa0.bed',
  }
  bedFiles = [defaultKit, ...bedFiles]
  const [panel, setPanel] = useState(bedFiles.length > 0 ? bedFiles[0].name : '')

  // Step 2: Update panel when bedFiles changes (e.g., after fetching data)
  useEffect(() => {
    if (bedFiles.length > 0 && panel === '') {
      setPanel(bedFiles[0].name)
    }
  }, [bedFiles])

  const handleDropMultiFile = async (filesToUpload) => {
    setFileQueue(filesToUpload)
    setFileNames(filesToUpload.map((file) => file.name))
    setReferences(filesToUpload.map(() => 'GRCh38'))
    setState('READY_TO_UPLOAD')

    // await uploadFile(fileToUpload)
    // await filesApi.refresh()
  }

  const handleUploadFiles = async (_filesToUpload) => {
    setState('UPLOADING')
  }

  const handleRemoveAll = () => {
    setFileQueue([])
  }

  const handleRemove = (file) => {
    setFileQueue(fileQueue.filter((f) => f !== file))
  }

  const handleCancel = () => {
    setFileQueue([])
    cancelTokenSource.cancel('Cancelled by user')
  }

  const NamingComponent = ({ file, index, fileNames, setFileNames, references, setReferences }) => {
    const handleFileNameChange = (value, index) => {
      const newFileNames = [...fileNames]
      newFileNames[index] = value
      setFileNames(newFileNames)
    }

    const handleReferenceChange = (value, index) => {
      const newReferences = [...references]
      newReferences[index] = value
      setReferences(newReferences)
    }
    return file ? (
      <Box>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                key={`fname-${file.name}`}
                label="File name"
                fullWidth
                value={fileNames[index]}
                onChange={(e) => handleFileNameChange(e.target.value, index)}
              ></TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                key={`fref-${file.name}`}
                label="Reference"
                select
                fullWidth
                value={references[index]}
                onChange={(e) => handleReferenceChange(e.target.value, index)}
              >
                <MenuItem value={'GRCh38'}>GRCh38</MenuItem>
                <MenuItem value={'GRCh37'}>GRCh37</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    ) : null
  }

  useEffect(() => {
    const uploadFilesStateMachine = async () => {
      if (state === 'UPLOADING') {
        if (fileQueue.length > 0) {
          setUploadProgress(0)
          cancelTokenSource = axios.CancelToken.source()
          await uploadFile(
            fileQueue[0],
            (value) => setUploadProgress(value),
            cancelTokenSource.token,
            fileNames[0],
            references[0],
          )

          setFileQueue(fileQueue.slice(1))
          setFileNames(fileNames.slice(1))
          setReferences(references.slice(1))
          await bedFilesApi.refresh()
        } else {
          // Done uploading all the files
          setBedUploadOpen(false)
          setState('IDLE')
        }
      }
    }

    uploadFilesStateMachine()
  }, [state, fileQueue])

  const handleClickAnnotate = () => {
    var realType = type,
      realTargetedType = targetedType,
      realPanel = panel,
      realNormalSample = normalSample
    if (panel === '(Default) hglft_genome_64dc_dcbaa0.bed') {
      // setType('Targeted')
      // setTargetedType('WholeExome')
      realType = 'Targeted'
      realTargetedType = 'WholeExome'
    }
    if (type === 'WholeGenome') {
      // setTargetedType('')
      // setPanel('')
      realTargetedType = ''
      realPanel = ''
    }
    if (analysisType !== 'TumorNormal') {
      realNormalSample = ''
    }
    onClickAnnotate({
      type: realType,
      panel: realPanel,
      targetedType: realTargetedType,
      analysisType,
      reference,
      isSnp: true,
      isCnv: false,
      normalSampleId: realNormalSample,
    })
  }

  const handleModalClose = () => {
    setBedUploadOpen(false)
    onClose()
  }

  // if the file is a vcf file, ask only for the reference (can be 37 or 38) and return a different object
  if (fileType === 'VCF') {
    return (
      <Dialog open={open} onClose={onClose}>
        {title && (
          <>
            <DialogTitle>Make annotation for {title}</DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
              <CardHeader title="Sequence" titleTypographyProps={{ variant: 'subtitle1' }} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Reference Genome"
                      defaultValue={0}
                      select
                      fullWidth
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                      sx={{ width: '100%' }}
                    >
                      <MenuItem value={'GRCh38'}>GRCh38</MenuItem>
                      <MenuItem value={'GRCh37'}>GRCh37</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose} color="error">
                Cancel
              </Button>
              <Button onClick={handleClickAnnotate} color="primary">
                Annotate
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={onClose}>
      {title && (
        <>
          <DialogTitle>Make annotation for {title}</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <CardHeader title="Sequence" titleTypographyProps={{ variant: 'subtitle1' }} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Type"
                    value={type}
                    fullWidth
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                    select
                  >
                    <MenuItem value="WholeGenome">Whole Genome</MenuItem>
                    <MenuItem value="Targeted">Targeted</MenuItem>
                  </TextField>
                </Grid>
                {type === 'Targeted' && (
                  <Grid item xs={6}>
                    <TextField
                      label="Capture Kit"
                      value={panel}
                      fullWidth
                      onChange={(e) => setPanel(e.target.value)}
                      select
                    >
                      {bedFiles.map((bedFile) => (
                        <MenuItem key={bedFile.name} value={bedFile.name}>
                          {bedFile.visible_name ? bedFile.visible_name : bedFile.original_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {type === 'Targeted' && (
                  <Grid item xs={6}>
                    <TextField
                      label="Targeted Type"
                      value={targetedType}
                      fullWidth
                      onChange={(e) => setTargetedType(e.target.value)}
                      select
                    >
                      <MenuItem value="WholeExome">Whole Exome</MenuItem>
                      <MenuItem value="ClinicalExome">Clinical Exome</MenuItem>
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CardHeader
                    title="Analysis"
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    sx={{ paddingTop: '5px', paddingLeft: '0' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Analysis Type"
                    value={analysisType}
                    fullWidth
                    onChange={(e) => setAnalysisType(e.target.value)}
                    select
                  >
                    <MenuItem value="Germline">Germline</MenuItem>
                    <MenuItem value="TumorOnly">Tumor Only</MenuItem>
                    <MenuItem value="TumorNormal">Tumor/Normal</MenuItem>
                  </TextField>
                </Grid>
                {analysisType === 'TumorNormal' && (
                  <Grid item xs={6}>
                    <TextField
                      label="Normal Sample"
                      value={normalSample}
                      fullWidth
                      onChange={(e) => setNormalSample(e.target.value)}
                      select
                    >
                      {filteredData.map((item) => (
                        <MenuItem key={item.fastq_pair_id} value={item.fastq_pair_id}>
                          {item.sample_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            {bedUploadOpen && (
              <>
                <CardHeader
                  title="Upload New Capture Kit"
                  sx={{ paddingTop: 0 }}
                  titleTypographyProps={{ variant: 'subtitle1' }}
                />
                <CardContent sx={{ '&:last-child': { paddingBottom: 0 } }}>
                  {state === 'UPLOADING' && fileQueue.length > 0 && <Box></Box>}
                  <UploadMultiFile
                    minimal
                    files={fileQueue}
                    onDrop={handleDropMultiFile}
                    onRemove={handleRemove}
                    onRemoveAll={handleRemoveAll}
                    onUploadFiles={handleUploadFiles}
                    locked={state === 'UPLOADING'}
                    onCancel={handleCancel}
                    NamingComponent={NamingComponent}
                    fileNames={fileNames}
                    setFileNames={setFileNames}
                    references={references}
                    setReferences={setReferences}
                  />
                </CardContent>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} color="error">
              Cancel
            </Button>
            <Button onClick={() => setBedUploadOpen(true)} color="secondary">
              Upload New Capture Kit
            </Button>
            <Button onClick={handleClickAnnotate} color="primary">
              Annotate
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
