import React from 'react'
import Page from 'src/components/Page'
import { Container, Grid } from '@material-ui/core'

// import FilesTable from './FilesTable'
import UploadKitsAndPanels from './UploadKitsAndPanels'

// TODO: UploadVCF and UploadFastq should be abstracted into a single component
// Likewise for FilesTable

const KitPanelUploadView = function () {
  return (
    <Page title="Kits & Panels | Genesus">
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <UploadKitsAndPanels isKits={true} />
        </Grid>
        <Grid item xs={6}>
          <UploadKitsAndPanels isKits={false} />
        </Grid>

        {/*<Grid item xs={12}>*/}
        {/*  <UploadFastq />*/}
        {/*</Grid>*/}

        {/*<Grid item xs={12}>*/}
        {/*  <FastqFilesTable />*/}
        {/*</Grid>*/}
      </Grid>
    </Page>
  )
}

export default KitPanelUploadView
