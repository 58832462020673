import PropTypes from 'prop-types'
import { findIndex } from 'lodash'
import { useEffect, useState, useRef, useMemo } from 'react'
//
import Scrollbar from 'src/components/Scrollbar'
import LightboxModal from 'src/components/LightboxModal'
import ChatMessageItem from './ChatMessageItem'

// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  conversation: PropTypes.object.isRequired,
}

export default function ChatMessageList({ conversation }) {
  const scrollRef = useRef()
  const [openLightbox, setOpenLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  // Memoize the messages array to avoid recalculating it on every render
  const messages = useMemo(() => {
    return (conversation?.messages || []).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  }, [conversation?.messages])

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      }
    }
    scrollMessagesToBottom()
  }, [messages])

  const images = useMemo(() => {
    return messages.filter((message) => message.contentType === 'image').map((message) => message.body)
  }, [messages])

  const handleOpenLightbox = (url) => {
    const selectedImage = findIndex(images, (index) => index === url)
    setOpenLightbox(true)
    setSelectedImage(selectedImage)
  }

  return (
    <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, maxHeight: '200px', flexGrow: 1 }}>
      {messages.map((message) => (
        <ChatMessageItem
          key={message.id}
          message={message}
          conversation={conversation}
          onOpenLightbox={handleOpenLightbox}
        />
      ))}

      <LightboxModal
        images={images}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </Scrollbar>
  )
}
