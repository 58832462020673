import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { forwardRef, useEffect, useCallback } from 'react'
// material
import { Box } from '@material-ui/core'
// utils
import track from '../utils/analytics'

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const { pathname } = useLocation()

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    sendPageViewEvent()
  }, [sendPageViewEvent])

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  )
})

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Page
